export const mainAsset =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/relieve-wine-maker.jpg?width=500';
export const secondaryAsset =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/finca-flag.jpg?width=500';

export const topRight =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/finca-carrodilla.jpg?width=800';
export const topLeft =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/finca-jump.jpg?width=800';

export const bottomMiddle =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/monte-cheers.jpg?width=800';

export const founders =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/valle_de_guadalupe_2.JPG?width=800';

export const tequila =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/Ryliv+Mexico+Pics/tequila.JPG?width=800';

export const cabo =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/Ryliv+Mexico+Pics/cabo.JPG?width=500';

export const mission =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/Ryliv+Mexico+Pics/mission.JPG?width=500';

export const oaxaca =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/Ryliv+Mexico+Pics/oaxaca.jpg?width=500';

export const tulum =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/Ryliv+Mexico+Pics/tulum.JPG?width=500';

export const valle =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/Ryliv+Mexico+Pics/valle_de_guadalupe.JPG?width=500';

export const monteXanic =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/monte-xanic.jpg?width=1000';

export const relieve =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/relieve.jpg?width=800';

export const relievePour =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/relieve-pour.jpg?width=800';

export const fincaCheers =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/finca-cheers-2.jpg?width=800';

export const picnicView =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/picnic-view.jpg?width=800';

export const picnic =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/picnic.jpg?width=800';

export const fincaFlag =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/finca-flag.jpg?width=800';

export const fincaFlagWide =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/finca-flag.jpg?width=2000';

export const francisco =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/finca-francisco.jpg?width=600';

export const oliviaVan =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/olivia-van-cropped.JPG?width=800';

export const film =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/film.JPG?width=800';

export const map =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/map.png?width=1000';

export const van =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/van-beach-square.jpg?width=600';

export const greg =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/greg.JPG?width=600';

///Hero Photos ////

export const heroOne =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/monte-xanic.jpg?width=2000';

export const heroTwo =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/relieve.jpg?width=2000';

export const heroThree =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/relieve-pour.jpg?width=2000';

export const heroFour =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/finca-cheers-2.jpg?width=2000';

export const horseOlivia =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/792DE69D-14BC-4B28-BCAA-0893FD8E8C20.JPG?width=800';

export const horseNewYear =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/AABB290F-964E-408F-B4DA-D39E027B081A.JPG?width=800';

export const horseElena =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/603E810C-8C7D-41B8-AEC1-9D84FCE41B5E.JPG?width=800';

export const horseAub =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/aubrey-horse.jpg?width=800';

export const cuatros =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/bura.jpg?width=800';

export const vineyard =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/vineyard.jpg?width=1920';

export const horseHero =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/AABB290F-964E-408F-B4DA-D39E027B081A.JPG?width=1920';

export const threeDots = 'https://ruadeseo.s3.us-west-1.amazonaws.com/three-dots.svg';

export const mexicoFlag =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/flag-horizontal.jpg?width=2000';

export const devin =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/devin.JPG?width=800';

export const hogan =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/hogan.JPG?width=800';

export const livWalk =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/liv-walk.jpg?width=800';

export const grapes =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/grapes.jpg?width=800';

export const aubsCheers =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/aubs-cheers.jpg?width=800';

export const fincaView =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/finca-view.jpg?width=800';

export const fincaDance =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/finca-dance.jpg?width=800';

export const relieveGather =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/FD30148B-F7E8-405C-9F67-9DFA0CDBF15E.JPG?width=2000';

export const fincaViewWide =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/finca-view.jpg?width=2000';

export const chai =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/chai.jpg?width=400';

export const valleFriends =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/valle.jpg?width=400';

export const bajaCali =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/baja-cali.jpg?width=400';

export const sip =
  'https://aihsvmgxxq.cloudimg.io/v7/ruadeseo.s3.us-west-1.amazonaws.com/sip.jpg?width=400';
