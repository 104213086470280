import React from 'react';
import classNames from 'classnames';
import s from './Overlay.scss';
import Close from '../../assets/svg/close.svg';

interface OverlayProps {
  overylayOpen: boolean;
  setOverlayOpen: (val: boolean) => void;
  children: React.ReactNode;
}

export const Overlay = ({ overylayOpen, setOverlayOpen, children }: OverlayProps) => {
  return (
    <div
      className={classNames(s.overlay, 'brandon_18')}
      style={{ display: overylayOpen ? 'block' : 'none' }}
    >
      <div className={s.overlayBox}>
        <button className={s.closeButton} onClick={() => setOverlayOpen(false)}>
          <Close />
        </button>
        <div>{children}</div>
      </div>
    </div>
  );
};
