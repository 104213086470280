export const reviews = [
  {
    id: 'L95M1pMU8P5uyUmCvCFWzQ',
    url:
      'https://www.yelp.com/biz/ruadeseo-san-diego-6?hrid=WFPfUcQdbXD2qDZFLCaJBA&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)',
    text:
      'Amazing!! From the beginning of planning our girls trip to the very end, Ruadeseo was nothing short of amazing!  Ryan, Olivia, and our driver, Cameron, m...',
    rating: 5,
    time_created: 1631084400000,
    user: {
      id: '5IXxY_VkoJUyZ1ICudqwEQ',
      profile_url: 'https://www.yelp.com/user_details?userid=5IXxY_VkoJUyZ1ICudqwEQ',
      image_url: 'https://s3-media0.fl.yelpcdn.com/photo/qV7xV6FRH5VxAURrptArsA/120s.jpg',
      name: 'Marissa S.',
    },
  },
  {
    id: '-ZLnilPSOo33DRc-zx8T1w',
    url:
      'https://www.yelp.com/biz/ruadeseo-san-diego-6?hrid=zcb9XjssEB92pDVAVpjm2g&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)',
    text: `Have you ever met someone that you wanted to adopt as a bestie within the first ten minutes of meeting them? Well if you haven't yet, you will, once you ...`,
    rating: 5,
    time_created: 1631084400000,
    user: {
      id: '6oe931svzMalH8nAtpMCAg',
      profile_url: 'https://www.yelp.com/user_details?userid=6oe931svzMalH8nAtpMCAg',
      image_url: '	https://s3-media0.fl.yelpcdn.com/photo/qgD8vKuxEu2_UlYjatBv8A/120s.jpg',
      name: 'Jesse H.',
    },
  },
  {
    id: 'PeZNfOkd9yAQBBci6es57g',
    url:
      'https://www.yelp.com/biz/ruadeseo-san-diego-6?hrid=jEu-7inVyWYZuQH5wDbJGw&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)',
    text:
      'I booked a one day trip with Ruadeseo for my birthday! And, it did not disappoint! From the time we got into the van we had the most wonderful experience....',
    rating: 5,
    time_created: 1631084400000,
    user: {
      id: 'Rcwj9_wVL4zr7MQ5Iy6a0Q',
      profile_url: 'https://www.yelp.com/user_details?userid=Rcwj9_wVL4zr7MQ5Iy6a0Q',
      image_url: 'https://s3-media0.fl.yelpcdn.com/photo/aplwzIooT_a2kRgPlJOGlg/120s.jpg',
      name: 'Kaela B.',
    },
  },
  {
    id: 'PeZNfOkd9yAQBBci6es57',
    url:
      'https://www.yelp.com/biz/ruadeseo-san-diego-6?hrid=LmoH-H0yp3dVUN-K7aS55w&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)',
    text:
      'Do not hesitate booking this excursion. We had a fantastic time! Olivia and Ryan were so nice and knowledgeable of the area. The wineries were amazing an...',
    rating: 5,
    time_created: 1631084400000,
    user: {
      id: 'Rcwj9_wVL4zr7MQ5Iy6a0Q',
      profile_url: 'https://www.yelp.com/user_details?userid=Rcwj9_wVL4zr7MQ5Iy6a0Q',
      image_url: 'https://s3-media0.fl.yelpcdn.com/photo/tcOAhaG7K9Y_QkRncLpi2g/120s.jpg',
      name: 'Kiki R.',
    },
  },
  {
    id: 'PeZNfOkd9yQBBci6es57g',
    url:
      'https://www.yelp.com/biz/ruadeseo-san-diego-6?hrid=PXi5boNyGtyNFnFGPHOYzw&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)',
    text: `Ryan and Olivia were amazing!! Everything was exceptional and such a smooth process. They didn't miss a beat! I was honestly hesitant about the process b...`,
    rating: 5,
    time_created: 1630998000000,
    user: {
      id: 'Rcwj9_wVL4zr7MQ5Iy6a0Q',
      profile_url: 'https://www.yelp.com/user_details?userid=Rcwj9_wVL4zr7MQ5Iy6a0Q',
      image_url: 'https://s3-media0.fl.yelpcdn.com/photo/4mxIU69hHnd6WQoUoGeFAA/120s.jpg',
      name: 'Jazmin V.',
    },
  },
  {
    id: 'PeZNfOkd9yAQBci6es57g',
    url:
      'https://www.yelp.com/biz/ruadeseo-san-diego-6?hrid=_grXqp2gVKg5nr0w_tVCfA&utm_campaign=www_review_share_popup&utm_medium=copy_link&utm_source=(direct)',
    text:
      "Our time with Olivia & Ryan was so memorable! Everything was a breeze - getting picked up at my friend's house, crossing the border, and making all of ou...",
    rating: 5,
    time_created: 1630911600000,
    user: {
      id: 'Rcwj9_wVL4zr7MQ5Iy6a0Q',
      profile_url: 'https://www.yelp.com/user_details?userid=Rcwj9_wVL4zr7MQ5Iy6a0Q',
      image_url: 'https://s3-media0.fl.yelpcdn.com/photo/NSX9XDwcpT15LI3Tf2OmRA/120s.jpg',
      name: 'Mary E.',
    },
  },
];
