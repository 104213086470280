export const photoGridCopy = {
  topLeftHeading: 'Free Pickup ',
  topLeftDescription: `Our private tours include free pickups and drop-offs at the location of your choice within San Diego County. Which means, there's no need to meet at the border!`,
  topRightHeading: 'Easy Border Crossing',
  topRightDescription:
    'We utilize the walking lane to save 2+ hours of waiting in the car. Anyone with Global Entry is welcome to stay in the van.',
  bottomMiddleHeading: 'Flexible Itineraries',
  bottomMiddleDescription: `Our private tours are not constrained to a certain set of wineries. Therefore, if you've been dying to try a certain winery let us know!`,
};

export const bookingBlock = {
  description: `We’ve all been in a toxic, dormant state for a while. It’s time to reawaken, get out, and get back into this incredible world.
  There is NO better way to reactivate all seven senses than on an epicurean adventure for the ages—down the coast, under the olive trees, and through the rolling vineyards of Mexico’s Valle de Guadalupe.`,
};

export const faqPage = [
  {
    title: 'Do I need a passport?',
    subtitle: 'Yes, either a passport, passport card, or global entry card is required.',
  },
  {
    title: 'How does entering into Mexico work?',
    subtitle:
      'The border is about a 20 minute drive from downtown San Diego. When crossing into Mexico, everyone stays in the car and your passport does not need to be shown. A quick inspection is done by border agents, and then get ready to crack open your ice cold Tecates!',
  },
  {
    title: 'How does entering back into the US work?',
    subtitle: `We have a vehicle pass for Global Entry/Sentri, so anyone with Global Entry is welcome to stay in the van. Otherwise,  Otherwise, you'll use the pedestrian lane, which normally takes 45 minutes or less. We do this to avoid the 2+ hour wait time in the general car lane.`,
  },
  {
    title: 'Can I bring wine back to the U.S. from Mexico?',
    subtitle:
      'Yes, one liter of alcohol per person may be entered into the United States duty-free. Typically two bottles of wine per person is allowed.',
  },
  {
    title: 'Can we bring our own alcoholic beverages?',
    subtitle: `Yes! We have two coolers in the van. One is loaded with water and the other is for your beverages.`,
  },
  {
    title: 'Can you accommodate specific winery/restaurant requests?',
    subtitle:
      'If you have upgraded to a private trip then yes, absolutely! As long as we have enough notice, this is something that we can easily accommodate.',
  },
  {
    title: 'What is your cancellation policy?',
    subtitle:
      'If you need to cancel for any reason, we offer full refunds within 72 hours of booking unless your trip date is within 72 hours. Otherwise, we are able to provide a 50% refund for individuals or the group up until the trip date.',
  },
  {
    title: 'How many hours is the wine daycation?',
    subtitle: 'Our wine tours run for about 12 hours!',
  },
  {
    title: 'What can I expect to pay aside from the daycation trip cost?',
    subtitle: 'Any personal shopping. Also, we also encourage guests to tip our drivers.',
  },
  {
    title: 'Does Baja California accept U.S. dollars?',
    subtitle: 'Yes, credit/debit cards and U.S. cash are widely accepted in Baja California.',
  },
  {
    title: 'Where do we depart from?',
    subtitle:
      'For trips that have been upgraded to private, we will pick you up from your desired pickup location within San Diego County. Public trips depart from Balboa Park (free parking).',
  },
  {
    title: 'What are the current COVID protocols in Mexico?',
    subtitle:
      'Masks are required when entering/exiting venues. Some places require a temperature check, and the use of hand sanitizer when entering. We provide masks in case you need one!',
  },
  {
    title: 'How long is the drive to Valle de Guadalupe?',
    subtitle:
      'With bathroom stops, it takes about 2 hours to drive to Valle de Guadalupe from downtown San Diego.',
  },
];
